/**
 * @author Manifold Finance, Inc.
 * @date July, 12, 2023
 * @license GPL-3.0
 */

// import { Inter } from "@next/font/google";
import DefaultLayout from '@/layout/DefaultLayout';
import { device } from '@/styles/common';
import { motion, useSpring } from 'framer-motion';
import Link from 'next/link';
import { useEffect } from 'react';
import { FallbackProps } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

// TODO: use i18next for translations
// {t( text for translation )}

const ErrorPage: React.FC<FallbackProps> = ({ error }) => {
  const { t } = useTranslation();
  // framer left var
  const left = useSpring(-200, { damping: 20 });

  useEffect(() => {
    setTimeout(() => {
      left.set(-50);
    }, 1000);
  }, []);

  return (
    <DefaultLayout>
      <Link href="/">
        <LogoImage src="/svg/MEV Text White.svg" alt="Manifold LSD" />
      </Link>
      <ErrorPageContainer>
        <h1> Access Error </h1>
        <br />
        <p>
          Access master program <br />
          Access master security <br />
          Access master program grid <br />
          <h2> Ah ah ah, you didn't say the magic word </h2>
          <br />
          You can go back to the{' '}
          <Link
            style={{
              color: '#00d1ff',
            }}
            href="https://mev.io/"
          >
            home page
          </Link>
        </p>
      </ErrorPageContainer>
      <GifImage style={{ left }} src="/images/nope.gif" />
      <BackgroundGradientCircle />
    </DefaultLayout>
  );
};

const ErrorPageContainer = styled.div`
  height: 100%;
  min-height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  position: relative;
  max-width: 80vw;
  align-self: center;

  z-index: 1;
  @media ${device.mobileL} {
    min-height: 40vh;
  }
`;

// Create a radial gradient that goes from transparent to light neon blue, and is centered with position absolute.
const BackgroundGradientCircle = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;

  pointer-events: none;

  background: radial-gradient(
    75% 75% at 50% 35%,
    rgba(74, 240, 240, 0.1) 0,

    rgba(255, 255, 255, 0) 100%
  );

  z-index: 0;

  @media ${device.mobileL} {
    width: 200px;
    height: 200px;
  }
`;

const LogoImage = styled.img`
  width: 136px;
  cursor: pointer;
  margin-top: 24px;
  margin-left: 56px;
`;

const GifImage = styled(motion.img)`
  width: 140px;
  position: fixed;
  bottom: 40%;
  transform: rotate(55deg);
`;

export default ErrorPage;
